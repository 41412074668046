<template>
  <div class="category--list">
    <div
      class="category--list-item"
      v-for="list in lists"
      @click="toggleCategory(list.value)"
      :key="list.value"
      :class="{ active: isActive(list.value) }"
      v-ripple
    >
      <p class="category--list-item-name">{{ list.label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "lists", "multiple"],
  name: "toggle-btn",
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {},
  methods: {
    toggleCategory(category) {
      if (this.multiple) {
        const index = this.selected.findIndex((c) => c === category);
        if (index === -1) this.selected.push(category);
        else this.selected.splice(index, 1);
      } else {
        if (this.selected === category) {
          this.selected = "";
        } else {
          this.selected = category;
        }
      }

      this.$emit("input", this.selected);
    },
    isActive(value) {
      if (this.multiple) {
        return this.selected.includes(value);
      }

      return this.selected === value;
    },
  },
  created() {
    if (this.multiple && typeof this.value === "string")
      throw new Error("V-model value has to be instance of array");

    if (!this.multiple && typeof this.value !== "string")
      throw new Error("v-model value has to be string");
  },
};
</script>

<style lang="scss" scoped>
.category--list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding-left: 5px;

  &-item {
    border: 0.5px solid #cccccc;
    border-radius: 90px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.51rem;
    padding: 0.5rem 1rem;

    &-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      text-transform: capitalize;
    }

    &.active {
      background: #f5f2e9;
    }
  }
}
</style>
