import Vue from "vue";
import Vuex from "vuex";

import popupModule from "@/store/popup";
import userModule from "@/store/user";
import petModule from "@/store/pet";
import feedModule from "@/store/feed";
import vetModule from "@/store/vet";
import notificationModule from "@/store/notification";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    HIDE_BOTTOM_BAR: false,
    isCordova: false,
  },
  getters: {},
  mutations: {
    SET_BOTTOM_BAR(state, payload) {
      state.HIDE_BOTTOM_BAR = payload;
    },
    SET_CORDOVA(state, payload) {
      state.isCordova = payload;
    },
  },
  actions: {},
  modules: {
    popup: popupModule,
    user: userModule,
    pet: petModule,
    feed: feedModule,
    vet: vetModule,
    notification: notificationModule,
  },
});
