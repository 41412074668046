export default {
  SET_FEED_LIST(state, payload) {
    state.feeds.push(payload);
  },
  SET_FEED(state, payload) {
    state.feeds = payload;
  },
  CLEAR_FEED(state) {
    state.feeds = [];
    state.page = 1;
    state.hasNext = true;
  },
  SET_SINGLE_POST(state, payload) {
    state.currentPost = payload;
  },
  SET_PET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_COMMENTS_LOADING(state, payload) {
    state.comment_loading = payload;
  },
  SET_FEED_PAGINATION(state, { page, hasNext }) {
    state.page = page;
    state.hasNext = hasNext;
  },
  SET_MY_POST(state, payload) {
    state.myPost = payload;
  },
  SET_MY_POST_PAGINATION(state, { page, hasNext }) {
    state.myPostPage = page;
    state.myPostNextPage = hasNext;
  },
  SET_DELETE_LOADING(state, payload) {
    state.deleteLoadingState = payload;
  },
  SET_POST_PROGRESS(state, payload) {
    state.postUploadProgress = payload;
  },
  CLEAR_POSTS(state) {
    state.myPost = [];
    state.myPostPage = 1;
    state.myPostNextPage = true;
  },
};
