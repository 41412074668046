export default {
  // Onboarding
  adoption_in_few_clicks: 'Adoption en quelques clics',
  get_started: 'Allons-y',
  share_animal_care: 'Partager les soins aux animaux',

  // Login Screen
  login: 'Connexion',
  enter_your_email_and_password:
    'Introduisez votre adresse e-mail et votre mot de passe',
  email: 'E-mail',
  password: 'Mot de passe',
  create_new_account: 'Créer un nouveau compte',
  or_signup_with: 'Ou se connecter',
  and: 'et',
  others: 'autres',
  dogs: 'Chiens',
  cats: 'Chats',
  other_animal: 'Autres animaux',

  // Create New Account
  create_account: 'Créer un compte',
  enter_your_details_below_and_get_started:
    'Complétez vos données et commencez',
  name: 'Nom',
  phone_number: 'Numéro de téléphone',
  enter_your_address: 'Introduisez votre adresse ',
  gender: 'Genre',
  male: 'Homme',
  female: 'Femme',
  other: 'Autre',
  next: 'Suivant',
  back: 'Retour',
  already_have_a_account: 'Avez-vous déjà un compte?',
  step_1_of_2: 'Etape 1 de 2',
  step_2_of_2: 'Etape 2 de 2',
  confirm_password: 'Confirmer mot de passe',

  // Preferences
  preferences: 'Préférences',
  family: 'Famille',
  kids_in_house: 'Enfants dans le ménage',
  skip: 'Suivant',
  do_you_have_a_garden_in_your_house: 'Avez-vous un jardin ?',
  garden_size: 'Taille du jardin',
  no_garden: 'Pas de jardin',
  less_than_200_square_meter: 'Moins de 200 mètres carrés',
  more_than_200_square_meter: 'Plus de 200 mètres carrés',
  how_many_animals_you_have_in_house: "Combien d'autres animaux avez-vous ?",

  // Fogot Password
  forgot_your_password: 'Mot de passe oublié ?',
  lets_get_you_new_password: 'Créer un nouveau mot de passe',
  submit: 'Soumettre',
  verify_your_otp: 'Vérifiez votre mot de passe OTP (à usage unique)',
  enter_the_6_digit_code_you_received_in_your_email:
    'Introduire le code à 6 chiffres envoyé à votre adresse e-mail',
  verify: 'Vérifier',

  //Social Wall
  enter_caption: 'Saisir légende',
  publish: 'Publier',
  upload_with: 'Chargement avec',
  add_your_comment: 'Ajoutez un commentaire',
  report_post: "Signaler l'article",
  block_user: "Bloquer l'utilisateur",
  terms_check_label:
    'En créant votre compte sur cette plateforme, vous acceptez notre politique de confidentialité.',
  terms_check_label1:
    'En créant votre compte sur cette plateforme, vous acceptez notre politique de confidentialité.',
  enter_a_reason: 'Entrez une raison',
  report_post_detail:
    'Aidez-nous à comprendre ce qui se passe avec ce poste. Comment le décririez-vous ?',
  report_user_detail:
    'Aidez-nous à comprendre ce qui se passe avec cet utilisateur. Comment le décririez-vous ?',
  thanks_for_reporting: "Merci d'avoir soumis un rapport.",
  review_for_reporting:
    'Nous examinerons votre rapport et vous informerons de notre décision dans les 24 heures.',
  pawed_by: 'Pawed par',

  //Pet list screen
  add_filters: 'Ajouter des filtres',
  species: 'Espèce',
  race: 'Race',
  add_race: 'Ajout race',
  close: 'Fermer',
  apply_filters: 'Appliquer filtres',
  clear_all_filters: 'Supprimer filtres',
  hi_human: 'Bonjour !',
  are_you_ready_to_be_my_new_family: "Etes-vous prêt à m'adopter ?",
  lets_explore: 'A la découverte',
  featured_pet: 'Présentation',
  all_listings: 'Tous les animaux',
  no_pet_found: "Pas d'animal de compagnie trouvé",
  no_featured_pet: "Pas d'animal de compagnie présenté",

  //Pet detail
  looking_for_a_new_home: "A la recherche d'une nouvelle maison",
  go_for_a_walk_with_someone: 'Promenade',
  info: 'Info',
  owner: 'Propriétaire',
  active_hour: 'Heures actives',
  kids_firendy: 'Aime les enfants ',
  not_kids_firendy: "N'aime pas les enfants",
  dogs_firendy: 'Aime les chiens',
  not_dogs_firendy: "N'aime pas les chiens",
  other_animals_friendly: 'Aime les autres animaux',
  not_other_animals_friendly: "N'aime pas les autres animaux",
  i_am_interested: 'Je suis intéressé',
  what_are_you_interested_in: "Qu'est ce qui vous intéresse ?",
  disclaimer: 'Décharge de responsabilité',
  your_contact_details: 'Vos données de contact',
  edit: 'Personnalisation',
  submit_request: 'Faire une demande',
  no_description: 'Pas de description',

  //Vet screen
  find_vets_near_you: 'Vétérinaires dans le voisinage',
  list: 'Liste',
  filters: 'Filters',
  use_my_current_location: "Utilisation de l'emplacement actuel",
  vet_detail: 'Coordonnées vétérinaire',
  opening_hours: "Heures d'ouverture",

  //Notification screen
  notifications: 'Notifications',
  no_notification_for_now: 'Pas de notification pour le moment',
  mark_all_read: 'Marquer comme lu',
  photo: 'Photo',

  //Profile screen
  adoption_requests: "Demande d'adoption",
  no_list: 'Pas de liste',
  adoption_list: "Liste d'adoption",
  co_ownership: 'Mede-Eigendom',
  feed_post: 'Fil de discussion',
  no_post_at_the_moment: 'Pas commentaires',
  cute_list: 'Liste des favoris',
  no_cute_list: 'Pas de favoris',
  general_info: 'Information générale',
  country_code: 'Code pays',
  edit_profile: 'Editer profil',
  create_new_password: 'Créer nouveau mot de passe',
  old_password: 'Ancien mot de passe',
  update_password: 'Mise à jour mot de passe',
  privacy_policy: 'Confidentialité',
  terms_and_conditions: 'Conditions générales',
  terms_and_conditions_title: "Conditions d'utilisation mPawer",
  privacy_policy_title: 'Politique de confidentialité',
  logout: 'Déconnexion',
  upload_profile_image: 'Télécharger photo profil',
  animal_in_house: 'Animal à la maison',
  kids: 'Enfants',
  garden: 'Jardin',
  animal: 'Animal',
  age: 'Age',
  enter_age_of_kid: 'Age des enfants',
  select_animal_type: "Choisir une espèce d'animal",

  phone: 'Téléphoner',
  kid: 'Enfant',
  change: 'Changer',
  change_password: 'Changer le mot de passe',
  current_location: 'Localisation actuelle',
  address: 'Adresse',
  closed: 'Fermée',
  cancel: 'Annuler',
  done: 'Terminé',
  report_listing: 'Liste des rapportsg',
  adoption: 'Adoption',
  go_for_a_walk: 'Aller se promener',
  in_house: 'Dans la maison',
  travel_with_pet: 'Voyager avec un animal de compagnie',
  how_would_you_like_to_own: 'Comment aimeriez-vous être propriétaire ?',
  cool: 'Cool',
  is_excited_about: 'est enthousiasmé par sa nouvelle maison',
  please_choose_in_which: 'Veuillez choisir les jours où vous aurez',
  enter_phone: 'Entrez le téléphone',
  enter_email: "Entrez l'e-mail",
  update: 'Mettre à jour',
  one_tap: 'Un robinet pour toute la journée',
  two_tap: 'Appuyez deux fois pour le matin',
  triple_tap: 'Appuyez trois fois pour Pm',
  pet_request_submitted: 'Demande soumise avec succèsy',
  pet_request_description:
    "Votre demande d'adoption a été soumise au propriétaire, pour plus de détails, vous pouvez vérifier l'état de la demande à partir de votre profil",
  my_profile: 'Mon profil',
  not: 'Pas',
  sponsored_content: 'Contenu sponsorisét',
  search_pet: 'Rechercher un animal de compagnie',
  enter_your_email: 'Entrer votre Email',
  save: 'Sauvegarder',
  setup_new_password: 'Configurer un nouveau mot de passe',
  password_updated_successfully:
    'Votre mot de passe a été mis à jour avec succès',
  delete_post: 'Supprimer le message',
  language: 'Langue',
  vets_located_near_you: 'Vétérinaires près de chez vous',
  get_direction: "Obtenir l'itinéraire",
  search_by_zip_code: 'Rechercher par code postal',
  enter_zip_code: 'Entrer le code postal',
  search_radius: 'Rayon de recherche',
  no_feature_pet: 'Aucun animal en vedette',
  house: 'Loger',
};
