export const API_END_POINT = "https://api.mpawer.app/api";
export const UPLOAD_API_END_POINT = "https://upload.mpawer.app/api";

export const GOOGLE_MAP_KEY = "AIzaSyAgtgpkvquvWbPJOgQK9wURtLHkbMwhRTU";
export const FACEBOOK_APP_ID = "871540716827797";
export const SENTRY_DSN =
  "https://86d2bb805c4c4d31824ae00b342de2b9@o1039674.ingest.sentry.io/6100443";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyArXzsM4tHGtytndiawrLN1kj8f72r97pY",
  authDomain: "mpawer-b8d5a.firebaseapp.com",
  projectId: "mpawer-b8d5a",
  storageBucket: "mpawer-b8d5a.appspot.com",
  messagingSenderId: "487242910148",
  appId: "1:487242910148:web:26c7c33c01b165cb977c91",
  measurementId: "G-G95V7TS771"
};
