import axios from '@/plugins/axios';

// belgium cords
const defaultCords = {
  lat: 50.5039,
  lng: -4.4699,
};

export default {
  namespaced: true,
  state: {
    vetList: [],
    locationMarkers: [],

    loading: false,
    locationAdded: false,
    center: defaultCords,
    currentLocationCords: defaultCords,

    currentLocationAddress: null,

    filters: {
      radius: 60,
      zipCode: '',
    },

    vet: null,
    vetLoading: false,
    reUpdate: false,
  },
  getters: {},
  mutations: {
    SET_VET_LIST(state, payload) {
      state.vetList = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_CENTER_POSITION(state, position) {
      state.center = position;
      state.locationMarkers = state.locationMarkers.map((location, idx) => {
        if (idx === 0) return location;
        const { lat, lng } = location.position;
        const vet = state.vetList.find((v) => v.id == location.id);
        if (lat == position.lat && lng == position.lng) {
          let activeThumbnail = null;
          if (vet) activeThumbnail = vet.active_thumbnail;
          return {
            ...location,
            icon: {
              url: activeThumbnail ? activeThumbnail : require('@/assets/svg/active-marker.svg'),
            },
            id: vet.id,
          };
        } else {
          return {
            ...location,
            id: vet.id,
            icon: {
              url: vet.thumbanil,
            },
          };
        }
      });

      state.reUpdate = !state.reUpdate;
    },
    PUSH_LOCATION_MARKER(state, location) {
      state.locationMarkers.push(location);
    },
    CLEAR_MARKER(state) {
      state.locationMarkers = [];
    },
    SET_LOCATION_ADDRESS(state, payload) {
      state.currentLocationAddress = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_CURRENT_LOCATION(state, payload) {
      state.currentLocationCords = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_LOCATION_ADDED(state, payload) {
      state.locationAdded = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_VET_LOADING(state, payload) {
      state.vetLoading = payload;
    },
    SET_VET_DETAIL(state, payload) {
      state.vet = payload;
    },
  },
  actions: {
    async FETCH_VET_LIST({ commit, state }) {
      try {
        commit('SET_LOADING', true);
        const {
          filters: { radius, zipCode },
          currentLocationCords: { lat, lng },
        } = state;

        const resp = await axios.get(
          `/user/show-by-radius/${lng}/${lat}/${radius}/${zipCode}`
        );

        const vets = resp.data.data;
        commit('SET_VET_LIST', vets);
        if (vets.length) {
          commit('SET_CENTER_POSITION', {
            lat: Number(vets[0].latitude),
            lng: Number(vets[0].longitude),
          });
        }

        vets.forEach((v) => {
          commit('PUSH_LOCATION_MARKER', {
            position: {
              lat: Number(v.latitude),
              lng: Number(v.longitude),
            },
            icon: {
              url: v.thumbanil || require('@/assets/images/pinned-dog.png'),
            },
            id: v.id,
            some: 'lalalalal',
          });
        });
        commit('SET_LOADING', false);
      } catch (error) {
        commit('SET_LOADING', false);
        console.log(error);
      }
    },

    async FETCH_VET_DETAILS({ commit }, id) {
      try {
        commit('SET_VET_LOADING', true);

        const resp = await axios.get(`/user/show-single-vet/${id}`);
        const { vet } = resp.data.data;
        let v = null;
        if (vet && vet.length) {
          v = vet[0];
          if (v.timing && typeof v.timing === 'string') {
            v.timing = JSON.parse(v.timing);
          }
          commit('SET_VET_DETAIL', v);
        }
        commit('SET_VET_LOADING', false);
      } catch (error) {
        commit('SET_VET_LOADING', false);
        console.log(error);
      }
    },
  },
};
